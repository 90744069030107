import React from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col } from 'reactstrap';

const InputRecibo = ({ id, estado, listadoRecibos, setListadoRecibos }) => {
  // Función para manejar el cambio en el checkbox
  const handleOnChange = () => {
    // Verificar si el ID está actualmente en listadoRecibos
    const isChecked = listadoRecibos.includes(id);

    if (!isChecked) {
      // Agregar el ID al listado de recibos seleccionados
      const updatedList = [...listadoRecibos, id];
      setListadoRecibos(updatedList);
    } else {
      // Remover el ID del listado de recibos seleccionados
      const updatedList = listadoRecibos.filter((item) => item !== id);
      setListadoRecibos(updatedList);
    }
  };

  return (
    <Row style={{ width: 100 }}>
      <Col
        sm="1"
        className={
          estado === 'Cobrado'
            ? 'd-flex align-items-center justify-content-center text-black-50'
            : 'd-flex align-items-center justify-content-center'
        }
      >
        <Input
          type="checkbox"
          id={id}
          name="option"
          value={id}
          disabled={estado === 'Cobrado'}
          onChange={handleOnChange}
          checked={listadoRecibos.includes(id)} // Marcar checkbox si el ID está en listadoRecibos
        />
      </Col>
      <Col style={{ padding: 0 }}>{id}</Col>
    </Row>
  );
};

InputRecibo.propTypes = {
  id: PropTypes.any,
  estado: PropTypes.string,
  listadoRecibos: PropTypes.array.isRequired,
  setListadoRecibos: PropTypes.func.isRequired,
};

export default InputRecibo;
