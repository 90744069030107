import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Button } from 'reactstrap';
import * as Icon from 'react-feather';
import ModalRecibo from './ModalRecibo';
import ModalLoader from '../../../layouts/modals/ModalLoader';

import InputRecibo from './InputRecibo';
import EstadoRecibo from './EstadoRecibo';
import MetodosPago from './MetodosPago';
import matriculacionApiService from '../../../services/http/matriculacionApiService';
import documentosApiService from '../../../services/http/documentosApiService';

const Recibos = ({ setListadoRecibos, listadoRecibos, idMatricula }) => {
  const [recibos, setRecibos] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isLoadingMetodosPago, setIsLoadingMetodosPago] = useState(true);
  const [isLoadingEstados, setIsLoadingEstados] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isProceedingLoading, setIsProceedingLoading] = useState(false);
  const [totalRecibos, setTotalRecibos] = useState(0);
  const [metodosPago, setMetodosPago] = useState([]);
  const [estados, setEstados] = useState([]);
  const [reloadRecibos, setReloadRecibos] = useState(true);
  console.log(recibos, 'yeee');
  // Preparamos las variables necesarias
  const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));

  useEffect(() => {
    const fetchMetodosPago = async () => {
      setIsLoadingMetodosPago(true);

      try {
        const dataMetodosPago = await matriculacionApiService.cargarMetodosPago({
          headquarter: 'CEDESCA',
        });
        setMetodosPago(dataMetodosPago);
      } catch (error) {
        setMetodosPago([]);
      } finally {
        setIsLoadingMetodosPago(false);
      }
    };

    const fetchEstados = async () => {
      setIsLoadingEstados(true);

      try {
        const dataEstadosRecibo = await matriculacionApiService.cargarEstadosRecibo({
          headquarter: 'CEDESCA',
        });
        setEstados(dataEstadosRecibo);
      } catch (error) {
        setEstados([]);
      } finally {
        setIsLoadingEstados(false);
      }
    };

    fetchMetodosPago();
    fetchEstados();
  }, []);

  useEffect(() => {
    const fetchRecibo = async () => {
      try {
        const dataRecibo = await matriculacionApiService.mostrarRecibos({
          headquarter: enrollmentSession.headquarter,
          id: idMatricula,
        });
        const a = [...dataRecibo];
        let precioTotalRecibos = 0;
        a.forEach((e) => {
          precioTotalRecibos += parseFloat(e.Neto);
        });
        setTotalRecibos(precioTotalRecibos);
        setRecibos(a);
      } catch (error) {
        setTotalRecibos(0);
        setRecibos([]);
      } finally {
        setReloadRecibos(false);
      }
    };

    if (reloadRecibos) {
      fetchRecibo();
    }
  }, [reloadRecibos]);

  const handleReciboChange = (reciboId, isChecked) => {
    const updatedListadoRecibos = isChecked
      ? [...listadoRecibos, reciboId] // Agregar ID al listado
      : listadoRecibos.filter((id) => id !== reciboId); // Remover ID del listado
    setListadoRecibos(updatedListadoRecibos);
  };

  const marcarTodos = () => {
    // Filtra los recibos que tienen Estado igual a 'Pendiente' y luego extrae sus NUM_Recibo
    const pendienteReciboIds = recibos
      .filter((recibo) => recibo.Estado === 'Pendiente')
      .map((recibo) => recibo.NUM_Recibo);

    // Establece el listado de recibos pendientes
    setListadoRecibos(pendienteReciboIds);

    // Establece que todos están marcados como chequeados
    setIsAllChecked(true);
  };

  const desmarcarTodos = () => {
    setListadoRecibos([]);
    setIsAllChecked(false);
  };

  const deleteIds = async () => {
    try {
      await matriculacionApiService.deleteRecibo({
        headquarter: enrollmentSession.headquarter,
        idRecibos: listadoRecibos,
      });
    } catch (error) {
      console.error('Eliminar recibo/s =>', error);
    } finally {
      setReloadRecibos(true);
      setListadoRecibos([]);
    }
  };

  const handleSaveRecibo = async (recibo) => {
    try {
      await matriculacionApiService.editRecibo({
        headquarter: enrollmentSession.headquarter,
        recibo: recibos.find((r) => r.NUM_Recibo === recibo.NUM_Recibo),
      });
    } catch (error) {
      console.error('Actualizar recibo =>', error);
    }
  };

  const openModal = () => {
    setIsVisible(true);
  };

  const downloadFile = async (e) => {
    setIsProceedingLoading(true);

    try {
      const dataBlob = await documentosApiService.exportarReciboPDF(
        { headquarter: enrollmentSession.headquarter, reciboId: e },
        { headquarter: enrollmentSession.headquarter },
      );
      const blobFinal = dataBlob.slice(0, dataBlob.size, 'application/pdf');
      const url = URL.createObjectURL(blobFinal);
      window.open(url, '_blank');
      setIsProceedingLoading(false);
    } catch (error) {
      setIsProceedingLoading(false);
    }
  };

  return (
    <>
      {isProceedingLoading ? <ModalLoader header="Generando documento" /> : ''}
      {isVisible && (
        <ModalRecibo
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          matricula={idMatricula}
          setReloadRecibos={setReloadRecibos}
        />
      )}
      {isAllChecked && !isLoadingEstados && !isLoadingMetodosPago && listadoRecibos?.length > 0 ? (
        <Button className="me-1 mb-2" onClick={desmarcarTodos}>
          <i className="bi bi-check-square" style={{ marginRight: '8px' }}></i>
          Desmarcar todos
        </Button>
      ) : (
        <Button className="me-1 mb-2" onClick={marcarTodos}>
          <i className="bi bi-check-square-fill" style={{ marginRight: '8px' }}></i>
          Marcar todos
        </Button>
      )}
      <Button className="me-1 mb-2" color="success" onClick={openModal}>
        Añadir
      </Button>
      <Button
        className="me-1 mb-2"
        color="danger"
        disabled={listadoRecibos.length === 0}
        onClick={deleteIds}
      >
        Borrar
      </Button>
      <Table
        responsive
        borderless
        style={{ fontSize: '13px', borderCollapse: 'separate', borderSpacing: '0 1em' }}
      >
        <thead>
          <tr>
            <th>ID recibo</th>
            <th>ID factura</th>
            <th>Concepto</th>
            <th>Fecha emisión</th>
            <th>Fecha vencimiento</th>
            <th>Fecha cobro</th>
            <th>Neto</th>
            <th>Bruto</th>
            <th>Descuento</th>
            <th>Tipo</th>
            <th>Forma de pago</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {recibos.map((r) => (
            <tr key={r.codigo}>
              <td id={r.codigo} className={r.Estado === 'Cobrado' ? 'text-black-50 ' : ''}>
                <InputRecibo
                  key={r.NUM_Recibo}
                  id={r.NUM_Recibo}
                  concepto={r.Concepto}
                  neto={r.Neto}
                  estado={r.Estado}
                  onReciboChange={handleReciboChange}
                  listadoRecibos={listadoRecibos}
                  setListadoRecibos={setListadoRecibos}
                />
              </td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Num_factura}</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Concepto}</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Fecha_Emision}</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>
                {r.Fecha_vencimiento}
              </td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Fecha_cobro}</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Neto}€</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Bruto}€</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.descuento}%</td>
              <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Tipo_cliente}</td>
              {r.Estado === 'Cobrado' ? (
                <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Forma_de_pago}</td>
              ) : (
                <td>
                  <MetodosPago
                    numRecibo={r.NUM_Recibo}
                    isLoading={isLoadingMetodosPago}
                    actual={r.idPago}
                    metodosPago={metodosPago}
                    setRecibos={setRecibos}
                  />
                </td>
              )}
              {r.Estado === 'Cobrado' ? (
                <td className={r.Estado === 'Cobrado' ? 'text-black-50' : ''}>{r.Estado}</td>
              ) : (
                <td>
                  <EstadoRecibo
                    numRecibo={r.NUM_Recibo}
                    isLoading={isLoadingEstados}
                    actual={r.IdEstado}
                    estados={estados}
                    setRecibos={setRecibos}
                  />
                </td>
              )}
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <Button size="sm" color="success" onClick={() => handleSaveRecibo(r)}>
                  <Icon.Save size={20} color="#FFF" className="align-text-center" />
                </Button>
                <Button size="sm" onClick={() => downloadFile(r.NUM_Recibo)}>
                  <Icon.Download size={20} className="align-text-center" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <span className="text-danger">Importe total de los recibos: {totalRecibos.toFixed(2)} €</span>
    </>
  );
};

Recibos.propTypes = {
  listadoRecibos: PropTypes.any,
  setListadoRecibos: PropTypes.any,
  idMatricula: PropTypes.any,
};

export default Recibos;
