import HttpService from './httpService';

class MatriculacionApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async getMatriculaById(id, { headquarter }) {
    return this.get(`matriculas/${id}`, { params: { headquarter } });
  }

  async cargarFidelizacion({ empresaId, procedence }) {
    return this.get('cargarFidelizacion', { params: { empresaId, procedence } });
  }

  async consultarBalanceGeneral({ headquarter, id }) {
    return this.get('consultarBalanceGeneral', { params: { headquarter, id } });
  }

  async consultarBalance({ headquarter, id }) {
    return this.get('consultarBalance', { params: { headquarter, id } });
  }

  async buscarGrupos({ headquarter, edit }) {
    return this.get('buscarGrupos', { params: { headquarter, edit } });
  }

  async cursosAprobados({ headquarter, id }) {
    return this.get('cursosAprobados', { params: { headquarter, id } });
  }

  async mostrarRecibos({ headquarter, id }) {
    return this.get('mostrarRecibos', { params: { headquarter, id } });
  }

  async buscarTipos({ headquarter, codigoGrupo, studentId }) {
    return this.get('buscarTipos', { params: { headquarter, codigoGrupo, studentId } });
  }

  async buscarModulos({ groupId, headquarter, studentId, enrollmentId }) {
    return this.get('buscarModulos', { params: { groupId, headquarter, studentId, enrollmentId } });
  }

  async obtenerTotal({ headquarter, id }) {
    return this.get('obtenerTotal', { params: { headquarter, id } });
  }

  async detalleMatricula({ headquarter, id }) {
    return this.get('detalleMatricula', { params: { headquarter, id } });
  }

  async buscarPreciosCiclo({ headquarter, groupId, id }) {
    return this.get('buscarPreciosCiclo', { params: { headquarter, groupId, id } });
  }

  async guardarMatricula(data, { headquarter, groupId, id }) {
    return this.post('guardarMatricula', data, { params: { headquarter, groupId, id } });
  }

  async agregarComentario(data) {
    return this.post('agregarComentario', data);
  }

  async actualizarModulos(data) {
    return this.post('actualizarModulos', data);
  }

  async obtenerConvocatorias({ headquarter, idMatricula, idActividad }) {
    return this.get('obtenerConvocatorias', { params: { headquarter, idMatricula, idActividad } });
  }

  async guardarEstadosActividad(data, { headquarter }) {
    return this.put('guardarEstadosActividad', data, { params: { headquarter } });
  }

  async cargarEstadosActividad({ headquarter }) {
    return this.get('cargarEstadosActividad', { params: { headquarter } });
  }

  async cargarListaConvocatorias({ headquarter }) {
    return this.get('cargarListaConvocatorias', { params: { headquarter } });
  }

  async cargarEstadosRecibo({ headquarter }) {
    return this.get('cargarEstadosRecibo', { params: { headquarter } });
  }

  async buscarCiclos({ headquarter }) {
    return this.get('buscarCiclos', { params: { headquarter } });
  }

  async buscarCentros({ headquarter }) {
    return this.get('buscarCentros', { params: { headquarter } });
  }

  async buscarEstados({ headquarter }) {
    return this.get('buscarEstados', { params: { headquarter } });
  }

  async filtrarSolicitudes(params) {
    return this.get('filtrarSolicitudes', {
      params,
      responseType: params.descarga ? 'blob' : 'json',
    });
  }

  async mostrarCambios({ headquarter, id }) {
    return this.get('mostrarCambios', { params: { headquarter, id } });
  }

  async estaCursando({ headquarter, id }) {
    return this.get('estaCursando', { params: { headquarter, id } });
  }

  async cargarMetodosPago({ headquarter }) {
    return this.get('cargarMetodosPago', { params: { headquarter } });
  }

  async cargarListaTipoCliente({ headquarter }) {
    return this.get('cargarListaTipoCliente', { params: { headquarter } });
  }

  async peticionActiva({ headquarter, id }) {
    return this.get('peticionActiva', { params: { headquarter, id } });
  }

  async crearConvocatoria(data, { headquarter }) {
    return this.post('crearConvocatoria', data, { params: { headquarter } });
  }

  async agregarAsignatura(data) {
    return this.post('agregarAsignatura', data);
  }

  async bajaMatricula(data) {
    return this.post('bajaMatricula', data);
  }

  async addRecibo({ headquarter, recibos = [], numMatricula }) {
    return this.post('addRecibo', { headquarter, recibos, numMatricula });
  }

  async editRecibo({ headquarter, recibo }) {
    return this.post('editRecibo', { headquarter, recibo });
  }

  async deleteRecibo({ headquarter, idRecibos }) {
    return this.post('deleteRecibo', { headquarter, idRecibos });
  }
}

const matriculacionApiService = new MatriculacionApiService(process.env.REACT_APP_NODE_API);
export default matriculacionApiService;
