import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';

import PropTypes from 'prop-types';
import { AuthContext } from '../../context/authContext';
import ModalClickable from '../modals/ModalClickable';
import ModalOptions from '../modals/ModalOptions';
import alumnosApiService from '../../services/http/alumnosApiService';

const ComponentCard = ({ children, title, subtitle, dropdown, handleClick, name, isProfile }) => {
  const { userOU } = useContext(AuthContext);

  const userSession = JSON.parse(localStorage.getItem('user'));
  const student = JSON.parse(localStorage.getItem('student'));

  const [loadingGoogleData, setLoadingGoogleData] = useState(false);
  const [googleData, setGoogleData] = useState(null);
  const [refreshGoogleData, setRefreshGoogleData] = useState(true);
  const stateAccount = useMemo(() => !googleData?.suspended, [googleData]);

  const [loadingActivation, setLoadingActivation] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalShown2, setIsModalShown2] = useState(false);

  useEffect(() => {
    const fetchStateAccount = async () => {
      setLoadingGoogleData(true);
      setRefreshGoogleData(false);

      try {
        const data = await alumnosApiService.recuperarGoogleDatosAlumno({
          headquarter: student.empresaId,
          studentId: student.id,
        });

        setGoogleData(data?.data);
      } catch (error) {
        setGoogleData(null);
      } finally {
        setLoadingGoogleData(false);
      }
    };

    if (isProfile && refreshGoogleData) fetchStateAccount();
  }, [isProfile, refreshGoogleData]);

  const handleActivationAccount = useCallback(async () => {
    setLoadingActivation(true);

    try {
      await alumnosApiService.activationAccount({
        headquarter: student.empresaId,
        idAlumno: student.id,
        isActive: !stateAccount,
      });

      setIsModalShown2(true);
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshGoogleData(true);
      setLoadingActivation(false);
      setIsModalShown(false);
    }
  }, [student, stateAccount]);

  return (
    <>
      {isModalShown2 && <ModalClickable header="Actualizado correctamente." />}
      {isModalShown && (
        <ModalOptions
          header="¿Desea cambiar el estado de alumno en el campus?"
          load={setIsModalShown}
          text1="Cancelar"
          color1="danger"
          text2="Aceptar"
          color2="primary"
          onAccept={handleActivationAccount}
          withLoader={loadingActivation}
        />
      )}
      <Card color="white">
        <CardTitle tag="h4" className="border-bottom px-4 py-3 mb-0">
          <div className="d-flex justify-content-between align-items-center">
            {dropdown === true ? (
              <>
                {title}
                <Button onClick={handleClick}>Ir al listado</Button>
              </>
            ) : (
              <>{title}</>
            )}
            {name && <span className="ms-3">{name}</span>}
            {isProfile &&
              !loadingGoogleData &&
              !!googleData &&
              (userOU.centro === 'SUPERADMIN' ||
                userSession.profileObj.email === 'nfeneux@cedetecgroup.com' ||
                userSession.profileObj.email === 'asanjose@cedetecgroup.com') && (
                <FormGroup
                  switch
                  className="d-flex flex-row align-items-center justify-content-center gap-3 p-0"
                >
                  <Label check htmlFor="active-account-switch" className="cursor-pointer">
                    {stateAccount ? 'Desactivar cuenta' : 'Activar cuenta'}
                  </Label>
                  <Input
                    type="switch"
                    name="active-account-switch"
                    id="active-account-switch"
                    checked={stateAccount}
                    style={{
                      backgroundColor: stateAccount ? '#53e17a' : '',
                      outline: 'none', // Fondo verde o rojo según el estado
                      margin: 0,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsModalShown(true);
                      setIsModalShown2(false);
                    }}
                  />
                </FormGroup>
              )}
          </div>
        </CardTitle>

        <CardBody className="p-4">
          <CardSubtitle className="text-muted mb-3">{subtitle || ''}</CardSubtitle>
          <div>{children}</div>
        </CardBody>
      </Card>
    </>
  );
};

ComponentCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  dropdown: PropTypes.bool,
  handleClick: PropTypes.any,
  name: PropTypes.string,
  isProfile: PropTypes.bool,
};

export default ComponentCard;
