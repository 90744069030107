import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

const EstadoRecibo = ({ estados = [], setRecibos, isLoading, actual, numRecibo }) => {
  const handleOnChange = (e) => {
    setRecibos((prev) =>
      prev.map((recibo) => {
        if (recibo.NUM_Recibo === numRecibo) {
          return { ...recibo, IdEstado: Number(e.target.value) };
        }
        return recibo;
      }),
    );
  };

  return (
    <>
      {isLoading || estados.length === 0 ? (
        ''
      ) : (
        <Input
          type="select"
          name={numRecibo}
          id={numRecibo}
          onChange={handleOnChange}
          defaultValue={actual}
          style={{
            width: '93px',
            height: '30px',
            fontSize: '13px',
            padding: '5px',
          }}
        >
          {estados.map((datos) => (
            <option key={datos.id} value={datos.id}>
              {datos.descripcion}
            </option>
          ))}
        </Input>
      )}
    </>
  );
};
EstadoRecibo.propTypes = {
  estados: PropTypes.any,
  setRecibos: PropTypes.any,
  isLoading: PropTypes.any,
  actual: PropTypes.any,
  numRecibo: PropTypes.any,
};
export default EstadoRecibo;
