import { useEffect, useState, useContext } from 'react';
import { Col, Row, List, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import ModalText from '../../../layouts/modals/ModalText';
import { AuthContext } from '../../../context/authContext';
import matriculacionApiService from '../../../services/http/matriculacionApiService';

const DatosAlumno = ({ idMatricula, precioFinal }) => {
  const [balance, setBalance] = useState(-1);
  const [dataApi, setDataApi] = useState([]);
  const [exepcion, setExcepcion] = useState('Sin excepción');

  const [modalTextOpen, setModalTextOpen] = useState(false);
  const { userOU } = useContext(AuthContext);

  // Preparamos las variables necesarias
  const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));

  // Función que obtiene los datos base en caso de no haber registros
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataBalance = await matriculacionApiService.consultarBalance({
          headquarter: enrollmentSession.headquarter,
          id: idMatricula,
        });
        setBalance(parseFloat(dataBalance.Balance));
      } catch (error) {
        setBalance(-1);
      }

      try {
        const dataMatricula = await matriculacionApiService.detalleMatricula({
          headquarter: enrollmentSession.headquarter,
          id: idMatricula,
        });

        setDataApi(dataMatricula);
        dataMatricula.forEach((a) => {
          if (a.Exenc_FCT !== 'Sin excepción') {
            setExcepcion(a.Exenc_FCT);
          }
        });
      } catch (error) {
        setDataApi([]);
      }
    };

    fetchData();
  }, []);

  const notHasDataApi = dataApi.length === 0;

  const handleBaja = () => {
    setModalTextOpen(true);
  };
  if (dataApi.length === 0) {
    return null; // No devolver nada si dataApi.length es 0
  }
  return (
    <>
      {modalTextOpen ? (
        <ModalText
          setState={setModalTextOpen}
          state={modalTextOpen}
          estadoMatricula={dataApi[0].estado}
          motivoBaja={dataApi[0].MotivoBaja}
          curso={dataApi[0].curso}
          doc={dataApi[0].url_doc}
        />
      ) : (
        ''
      )}
      <Row>
        <Col sm="6">
          <h4>Alumno</h4>
          {notHasDataApi ? (
            <p className="mb-2">Sin datos</p>
          ) : (
            <p className="mb-2">
              {dataApi[0].sNombre} {dataApi[0].sApellidos}
            </p>
          )}
        </Col>
        <Col sm="2"></Col>
        {dataApi.length > 0 && dataApi[0].estado !== 'Activa' ? (
          ''
        ) : (
          <Col sm="4">
            {userOU.centro === 'ORIENTACIÓN' ? (
              ''
            ) : (
              <Button onClick={handleBaja} color="danger" className="float-end">
                <small>
                  {userOU.centro === 'SECRETARÍA' || userOU.centro === 'SUPERADMIN'
                    ? 'Dar de baja matrícula'
                    : 'Solicitar baja matrícula'}
                </small>
              </Button>
            )}
          </Col>
        )}
        {(userOU.centro === 'SUPERADMIN' || userOU.centro === 'SECRETARÍA') &&
        dataApi[0].estado === 'Pendiente baja' ? (
          <Col sm="4">
            <Button onClick={handleBaja} color="warning" className="float-end">
              <small>Ver solicitud baja matrícula</small>
            </Button>
          </Col>
        ) : (
          ''
        )}
        {dataApi.length > 0 && dataApi[0].estado === 'Baja' ? (
          <Col sm="4">
            <p style={{ marginBottom: '6px' }}>
              <b>Fecha de baja:</b>{' '}
              {new Date(dataApi[0].FechaBaja).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </p>
            <p style={{ marginBottom: '6px' }}>
              <b>Motivo:</b>{' '}
              {dataApi[0].MotivoBaja === '' || dataApi[0].MotivoBaja === null
                ? '-'
                : dataApi[0].MotivoBaja}
            </p>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <h4>Curso</h4>
      <List>{notHasDataApi ? <li>Sin datos</li> : <li>{dataApi[0].curso}</li>}</List>
      <h4 className="mt-3">Tipo de matrícula</h4>
      <List>{notHasDataApi ? <li>Sin datos</li> : <li>{dataApi[0].tipo}</li>}</List>
      <h4 className="mt-3">Precio inscripción</h4>
      <List>{notHasDataApi ? <li>Sin datos</li> : <li>{dataApi[0].pr} €</li>}</List>
      <h4 className="mt-3">Precio actividades</h4>
      <List>{notHasDataApi ? <li>Sin datos</li> : <li>{precioFinal} €</li>}</List>
      <h4 className="mt-3">Excepción</h4>
      <List>{notHasDataApi ? <li>Sin datos</li> : <li>{exepcion}</li>}</List>
      <h4>Balance</h4>
      <List>
        {' '}
        <li>{balance} €</li>
      </List>
    </>
  );
};

DatosAlumno.propTypes = {
  idMatricula: PropTypes.any,
  precioFinal: PropTypes.any,
};

export default DatosAlumno;
