import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

const MetodosPago = ({ metodosPago, setRecibos, isLoading, actual, numRecibo }) => {
  const handleOnChange = (e) => {
    setRecibos((prev) =>
      prev.map((recibo) => {
        if (recibo.NUM_Recibo === numRecibo) {
          return { ...recibo, idPago: Number(e.target.value) };
        }
        return recibo;
      }),
    );
  };

  return (
    <>
      {isLoading || metodosPago.length === 0 ? (
        ''
      ) : (
        <Input
          type="select"
          name={numRecibo}
          id={numRecibo}
          onChange={handleOnChange}
          value={actual}
          style={{
            width: '150px',
            height: '30px',
            fontSize: '13px',
            padding: '5px',
          }}
        >
          {metodosPago.map((datos) => (
            <option key={datos.id} value={datos.id}>
              {datos.descripcion}
            </option>
          ))}
        </Input>
      )}
    </>
  );
};
MetodosPago.propTypes = {
  metodosPago: PropTypes.any,
  setRecibos: PropTypes.any,
  isLoading: PropTypes.any,
  actual: PropTypes.any,
  numRecibo: PropTypes.any,
};
export default MetodosPago;
