import HttpService from './httpService';

class ActasApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async cargarActas({ headquarter, degree, years, type }) {
    return this.get('cargarActas', { params: { headquarter, degree, years, type } });
  }

  async cargarActividades({ headquarter, group, type }) {
    return this.get('cargarActividades', { params: { headquarter, group, type } });
  }

  async buscarDatosActas(data) {
    return this.post('buscarDatosActas', data, { responseType: 'blob' });
  }

  async buscarAlumnosActas({ type, group, fecha, courseYear, headquarter, isActa }) {
    return this.get('buscarAlumnosActas', {
      params: { type, group, fecha, courseYear, headquarter, isActa },
    });
  }
}

const actasApiService = new ActasApiService(process.env.REACT_APP_NODE_API);
export default actasApiService;
