import { useState, React, useMemo } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { validarEmail } from '../../functions/documentType';
import BuscarAlumnos from '../../components/envioMails/BuscarAlumnos';
import RedactarMail from '../../components/envioMails/RedactarMail';
import envioMailService from '../../services/http/envioEmailService';
import ModalClickable from '../../layouts/modals/ModalClickable';

const EnvioMails = () => {
  const [pageCount, setPageCount] = useState(0);
  const [alumnos, setAlumnos] = useState([]);
  const [asunto, setAsunto] = useState('');
  const [cuerpo, setCuerpo] = useState('');
  const [selectedEmails, setSelectedEmails] = useState({});
  const [isSent, setIsSent] = useState(false);
  const [filters, setFilters] = useState({
    empresaId: 'CEDESCA',
    idFormationCenter: [],
    group: [],
  });
  const [emailGroup, setEmailGroup] = useState([]);
  const [emailResult, setEmailResult] = useState(null);

  const alumnosFiltrados = useMemo(
    () => alumnos.filter((alumno) => Object.keys(selectedEmails).includes(String(alumno.CODIGO))),
    [selectedEmails, alumnos],
  );

  const emailsDestinatarios = useMemo(
    () =>
      Object.values(selectedEmails)
        .flat()
        .filter((e) => !!e)
        .map((e) => e.trim()),
    [selectedEmails],
  );

  const handleOnClick = () => {
    if (pageCount === 1) {
      setPageCount(0);
    } else {
      setPageCount(1);
    }
  };

  const handleSend = async () => {
    const validEmails = emailsDestinatarios.filter((e) => validarEmail(e));
    const ccodclis = Object.keys(selectedEmails);
    const values = {
      email: 'no-reply@cedetecgroup.com',
      emails: validEmails,
      contenido: cuerpo,
      ccodclis,
      asunto,
      alumnosSelecteds: alumnosFiltrados,
    };

    try {
      // Marcar como enviando antes de iniciar la solicitud
      setIsSent(false);

      // Llamada a la API para enviar el correo de manera asíncrona
      const response = await envioMailService.enviarCorreoMasivo(values, {
        empresaId: filters.empresaId,
      });
      setEmailResult(response);

      // Actualizar el estado una vez que el correo se ha enviado
      setIsSent(true);
    } catch (error) {
      console.error('Error al enviar el correo:', error);
      setIsSent(false);
    }
  };

  const resultMessage = useMemo(() => {
    const numberCorreosOkey = emailResult?.correosOkey?.length ?? 0;
    const correosFailed = emailResult?.correosFailed ?? [];
    const numberCorreosFailed = correosFailed.length;

    return (
      <Col>
        <Row className="m-1 justify-content-center">Petición de envío realizada.</Row>
        <Row className="m-1 text-start">
          El mail se ha enviado correctamente a {numberCorreosOkey} correo
          {numberCorreosOkey === 1 ? '' : 's'}.
        </Row>
        <Row className="m-1 text-start">
          {numberCorreosFailed > 0
            ? `Hay ${numberCorreosFailed} correo${
                numberCorreosFailed > 1 ? 's' : ''
              } con error de envío.`
            : 'No ha habido ningún correo con error de envío.'}
        </Row>
        {emailResult?.isConfirmationEmailOkey && (
          <Row className="m-1 text-start">
            Para mayor información, mira el correo de confirmación de mails que se te ha enviado.
          </Row>
        )}
      </Col>
    );
  }, [emailResult]);

  return (
    <>
      {isSent && <ModalClickable color="primary" header={resultMessage} url="/alumnos" />}
      <Col className="mt-3" md="12">
        {pageCount === 0 ? (
          <BuscarAlumnos
            setPageCount={setPageCount}
            alumnos={alumnos}
            setAlumnos={setAlumnos}
            setSelectedEmails={setSelectedEmails}
            selectedEmails={selectedEmails}
            filters={filters}
            setFilters={setFilters}
            emailGroup={emailGroup}
            setEmailGroup={setEmailGroup}
          />
        ) : (
          <RedactarMail
            setPageCount={setPageCount}
            setCuerpo={setCuerpo}
            setAsunto={setAsunto}
            asunto={asunto}
            cuerpo={cuerpo}
            emailsDestinatarios={emailsDestinatarios}
          />
        )}
        {alumnos !== '' && pageCount === 0 ? (
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button
                onClick={handleOnClick}
                disabled={Object.keys(selectedEmails).length === 0 || emailGroup.length === 0}
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {pageCount === 1 ? (
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button onClick={handleOnClick}>Anterior</Button>
            </Col>
            <Col>
              <Button onClick={handleSend} disabled={cuerpo.length === 0 || asunto.length === 0}>
                Enviar
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Col>
    </>
  );
};
export default EnvioMails;
