import { useState, useEffect } from 'react';

import { Col, Form, Input, Row, Button, Spinner, Label } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import Select from 'react-select';
import alumnosApiService from '../../services/http/alumnosApiService';
import recibosApiService from '../../services/http/recibosApiService';

const FormularioRecibos = () => {
  const translations = {
    previousText: '<',
    nextText: '>',
    loadingText: 'Cargando..',
    ofText: 'de',
    rowsText: 'líneas',
    noDataText: 'Datos no encontrados',
    pageText: 'Página',
  };
  const [formationList, setFormationList] = useState('');
  const [isFormSubmittable, setIsFormSubmittable] = useState(true);
  const [isTableNotLoading, setIsTableNotLoading] = useState(true);
  const [formationCenter, setFormationCenter] = useState('');
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [fechaError, setFechaError] = useState('');
  const [headquarter, setHeadquarter] = useState('CEDESCA');
  const [idRecibo, setIdRecibo] = useState({ data: '', error: '' });
  const [tipoCliente, setTipoCliente] = useState('0');
  const [data, setData] = useState(null);
  // const [tipoCliente, setTipoCliente] = useState({ data: '', error: '' });
  const [name, setName] = useState({ data: '', error: '' });
  const [lastname, setLastname] = useState({ data: '', error: '' });
  // const [centro, setCentro] = useState({ data: '', error: '' });

  useEffect(() => {
    const fetch = async () => {
      try {
        const dataCentrosFormacion = await alumnosApiService.cargarCentrosFormacion({
          empresaId: 'CEDESCA',
        });
        dataCentrosFormacion.unshift({ Id: '', Nombre: 'Todos' });
        const opcionesRenombradas = dataCentrosFormacion.map((opcion) => ({
          value: opcion.Id,
          label: opcion.Nombre,
        }));
        setFormationList(opcionesRenombradas);
        setIsFormSubmittable(true);
      } catch (error) {
        setFormationList('');
        setIsFormSubmittable(false);
      }
    };

    fetch();
  }, []);
  const handleHeadquarter = (e) => {
    setHeadquarter(e.target.value);
  };
  const handleClientType = (e) => {
    setTipoCliente(e.target.value);
  };
  const handleFechaInicio = (value) => {
    const nuevaFechaInicio = value ? moment(value) : null; // Si no hay valor, asigna null

    // Si fechaInicio se borra, también limpiar fechaFinal
    if (!nuevaFechaInicio) {
      setFechaFinal(null);
    } else if (fechaFinal && nuevaFechaInicio.isAfter(moment(fechaFinal))) {
      setFechaError('La fecha de inicio no puede ser posterior a la fecha final.');
    } else {
      setFechaError('');
    }

    setFechaInicio(nuevaFechaInicio);
  };

  const handleFechaFinal = (value) => {
    const nuevaFechaFinal = moment(value);
    if (fechaInicio && nuevaFechaFinal.isBefore(moment(fechaInicio))) {
      setFechaError('La fecha final no puede ser anterior a la fecha de inicio.');
    } else {
      setFechaError('');
    }
    setFechaFinal(nuevaFechaFinal);
  };

  const handleFormationCenter = (selected) => {
    setFormationCenter(selected);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsTableNotLoading(false);
    const params = {
      empresaId: headquarter,
      nombre: name.data,
      apellido: lastname.data,
      idRecibo: idRecibo.data,
      tipoCliente,
      fechaI: fechaInicio ? fechaInicio.format('YYYY-MM-DD') : '',
      fechaF: fechaFinal ? fechaFinal.format('YYYY-MM-DD') : '',
      centrosFormacion: formationCenter,
      excel: false,
    };
    let actualData = [];
    try {
      actualData = await recibosApiService.buscarListadoRecibos(params);
      setData([...actualData]);
    } catch (error) {
      // No hacer nada específico aquí.
    } finally {
      setIsTableNotLoading(true);
    }
  };

  const downloadExcel = async (e) => {
    e.preventDefault();
    setIsTableNotLoading(false);
    const params = {
      empresaId: headquarter,
      nombre: name.data,
      apellido: lastname.data,
      idRecibo: idRecibo.data,
      tipoCliente,
      fechaI: fechaInicio ? fechaInicio.format('YYYY-MM-DD') : '',
      fechaF: fechaFinal ? fechaFinal.format('YYYY-MM-DD') : '',
      centrosFormacion: formationCenter,
      excel: true,
    };

    try {
      const dataSolicitudes = await recibosApiService.buscarListadoRecibos(params);
      // Intenta leer el Blob como texto para ver si es un JSON

      const url2 = window.URL.createObjectURL(dataSolicitudes);
      const a = document.createElement('a');
      a.href = url2;
      a.download = 'document.xlsx';
      a.click();
      window.URL.revokeObjectURL(url2);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    } finally {
      setIsTableNotLoading(true);
    }
  };

  const handleNameOnChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setName({ data: '', error: '' });
      setIsFormSubmittable(true);
    } else if (!e.target.value.trim().match(/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/)) {
      setName({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else if (e.target.value.trim().length === 1) {
      setName({ data: '', error: 'length' });
      setIsFormSubmittable(false);
    } else {
      setName({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const handleLastNameOnChange = (e) => {
    if (!e.target.value.trim().match(/^(?![\s.]+$)[a-zA-ZÀ-ÿ\s.]*$/)) {
      setLastname({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else if (e.target.value.trim().length === 1) {
      setLastname({ data: '', error: 'length' });
      setIsFormSubmittable(false);
    } else {
      setLastname({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const handleIDOnChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setIdRecibo({ data: '', error: '' });
      setIsFormSubmittable(true);
    } else if (e.target.value.trim().match(/^[^0-9]*$/)) {
      setIdRecibo({ data: '', error: 'format' });
      setIsFormSubmittable(false);
    } else {
      setIdRecibo({ data: e.target.value.trim(), error: '' });
      setIsFormSubmittable(true);
    }
  };
  const CustomClearText = () => 'borrar todo';
  const ClearIndicator = (props) => {
    const {
      // eslint-disable-next-line react/prop-types
      children = <CustomClearText />,
      // eslint-disable-next-line react/prop-types
      getStyles,
      // eslint-disable-next-line react/prop-types
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px' }}>{children}</div>
      </div>
    );
  };

  return (
    <>
      <Form onSubmit={handleOnSubmit}>
        <Row className="mt-md-3" style={{ alignItems: 'flex-end' }}>
          <Col sm="2" className="mt-1 mt-md-0 ">
            <label htmlFor="name" className="form-label">
              Nombre
            </label>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Buscar por nombre"
              onChange={handleNameOnChange}
              onInput={handleNameOnChange}
              className={name.error !== '' ? 'border border-danger error' : ''}
              defaultValue={name.data}
              disabled={isTableNotLoading ? '' : 'disabled'}
            />
          </Col>
          <Col sm="3">
            <label htmlFor="lastname" className="form-label">
              Apellidos
            </label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Buscar por apellidos"
              onChange={handleLastNameOnChange}
              onInput={handleLastNameOnChange}
              className={lastname.error !== '' ? 'border border-danger error' : ''}
              defaultValue={lastname.data}
              disabled={isTableNotLoading ? '' : 'disabled'}
            />
          </Col>
          <Col sm="2">
            <label htmlFor="idRecibo" className="form-label">
              ID
            </label>
            <Input
              type="text"
              name="idRecibo"
              id="idRecibo"
              placeholder="Buscar por ID"
              onChange={handleIDOnChange}
              onInput={handleIDOnChange}
              className={idRecibo.error !== '' ? 'border border-danger error' : ''}
              defaultValue={idRecibo.data}
              disabled={isTableNotLoading ? '' : 'disabled'}
            />
          </Col>
          <Col sm="5" className="mt-1 mt-md-0 ">
            <label htmlFor="centroFormacion" className="form-label">
              Centros de estudio
            </label>

            <Select
              options={formationList}
              closeMenuOnSelect={false}
              value={formationCenter}
              isMulti
              onChange={handleFormationCenter}
              isDisabled={isTableNotLoading ? '' : 'disabled'}
              components={{ ClearIndicator }}
              placeholder="Seleccionar..."
            />
          </Col>
        </Row>
        <Row className="mt-auto mt-md-2 d-flex flex-column flex-md-row">
          <Col sm="3">
            <label htmlFor="clientes" className="form-label">
              Tipo de cliente
            </label>

            <select
              className="form-select"
              name="clientes"
              id="clientes"
              onChange={handleClientType}
              disabled={isTableNotLoading ? '' : 'disabled'}
              value={tipoCliente}
            >
              <option value="0">Todos</option>
              <option value="1">Alumno</option>
              <option value="2">Empresa</option>
            </select>
          </Col>
          <Col sm="3">
            <Label for="fechaInicio">Fecha de Inicio</Label>
            <Datetime
              id="fechaInicio"
              name="fechaInicio"
              timeFormat={false}
              onChange={handleFechaInicio}
              value={fechaInicio ? fechaInicio.format('DD/MM/YYYY') : ''}
              dateFormat="DD/MM/YYYY"
              inputProps={{ disabled: isTableNotLoading ? '' : 'disabled' }}
            />
          </Col>

          <Col sm="3">
            <Label for="fechaFinal">Fecha Final</Label>
            <Datetime
              id="fechaFinal"
              name="fechaFinal"
              timeFormat={false}
              onChange={handleFechaFinal}
              value={fechaFinal ? fechaFinal.format('DD/MM/YYYY') : ''}
              dateFormat="DD/MM/YYYY"
              inputProps={{ disabled: isTableNotLoading || !fechaInicio ? '' : 'disabled' }}
            />
            {fechaError && <span className="text-danger tiny">{fechaError}</span>}
          </Col>
          <Col sm="3">
            <label htmlFor="centros" className="form-label">
              Empresa
            </label>

            <select
              className="form-select"
              name="centros"
              id="centros"
              onChange={handleHeadquarter}
              disabled={isTableNotLoading ? '' : 'disabled'}
              value={headquarter}
            >
              <option value="CEDESCA">CEDESCA</option>
              <option value="CNTEC">CNTEC</option>
            </select>
          </Col>
        </Row>
        <Row
          className="mt-auto mt-md-2 d-flex justify-content-end align-items-center"
          style={{ alignItems: 'flex-end' }}
        >
          <Col sm="1" className="mt-1 float-end">
            <Button
              type="submit"
              className={
                !isFormSubmittable || !isTableNotLoading ? 'mt-0 disabled float-end' : 'mt-0'
              }
            >
              {!isTableNotLoading ? (
                <>
                  <Spinner color="primary" style={{ height: '15px', width: '15px' }} />{' '}
                </>
              ) : (
                'Buscar'
              )}
            </Button>
          </Col>
          <Col sm="1" className="mt-1 float-end">
            <Button
              color="success"
              type="button"
              onClick={downloadExcel}
              disabled={data === '' || !isTableNotLoading}
            >
              <i className="bi-file-earmark-spreadsheet" />
            </Button>
          </Col>
        </Row>
      </Form>
      {data === null ? (
        ''
      ) : (
        <ReactTable
          {...translations}
          columns={[
            {
              Header: 'Empresa',
              accessor: 'empresa',
            },
            {
              Header: 'Recibo',
              accessor: 'recibo', // Relacionado con re.num_recibo
            },
            {
              Header: 'Factura',
              accessor: 'factura', // Relacionado con re.n_factura
            },
            {
              Header: 'Fecha de Emisión',
              accessor: 'fecha_emision', // Relacionado con re.fecha_fra
            },
            {
              Header: 'Fecha de Vencimiento',
              accessor: 'fecha_vto', // Relacionado con re.fecha_vto
            },
            {
              Header: 'Vencimiento Aplazado',
              accessor: 'vencimiento_aplazado', // Relacionado con la subconsulta para la fecha de vencimiento aplazado
            },
            {
              Header: 'Fecha de Cobro',
              accessor: 'fecha_cobro', // Relacionado con la subconsulta para la fecha_cobro
            },
            {
              Header: 'Cliente',
              accessor: 'cliente', // Relacionado con re.idcliente
            },
            {
              Header: 'Centro',
              accessor: 'idcentro', // Relacionado con re.idcentro
            },
            {
              Header: 'Nombre',
              accessor: 'nombre', // Relacionado con al.cnomcli o cli.cnomcli, dependiendo de re.idtipocliente
            },
            {
              Header: 'Tipo de Cliente',
              accessor: 'tipo_cliente', // Relacionado con la condición IF en re.idtipocliente
            },
            {
              Header: 'Forma de Pago',
              accessor: 'forma_de_pago', // Relacionado con fp.descripcion
            },
            {
              Header: 'Concepto',
              accessor: 'concepto', // Relacionado con re.concepto
            },
            {
              Header: 'Importe',
              accessor: 'importe', // Relacionado con re.importe
            },
            {
              Header: 'Estado',
              accessor: 'estado', // Relacionado con er.descripcion
            },
            {
              Header: 'Facturado',
              accessor: 'facturado', // Relacionado con la condición en re.n_factura
            },
            {
              Header: 'Caja',
              accessor: 'caja', // Relacionado con re.idcaja
            },
            {
              Header: 'Sede',
              accessor: 'sede', // Relacionado con tc.nombre
            },
            {
              Header: 'Referencia',
              accessor: 'referencia', // Relacionado con re.referencia
            },
            {
              Header: 'Apellido Alumno',
              accessor: 'Apellido_Alumno', // Relacionado con al2.sApellidos
            },
            {
              Header: 'Nombre Alumno',
              accessor: 'Nombre_Alumno', // Relacionado con al2.sNombre
            },
            {
              Header: 'Grupo',
              accessor: 'GRUPO', // Relacionado con la subconsulta para la descripción del grupo
            },
            {
              Header: 'Estado de Matrícula',
              accessor: 'Estado_Matricula', // Relacionado con la condición CASE en mt.estado
            },
            {
              Header: 'Fecha de Baja',
              accessor: 'Fecha_baja', // Relacionado con mt.FinMatricula si mt.estado es 3
            },
            {
              Header: 'Motivo de Baja',
              accessor: 'MotivoBaja', // Relacionado con mt.MotivoBaja
            },
          ]}
          data={data}
          defaultPageSize={10}
          showPaginationBottom
          className="-striped -highlight mt-5 text-center"
        />
      )}
    </>
  );
};

export default FormularioRecibos;
