import { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap';
import { httpServiceInstanceNode } from '../../services/http/httpService';

const ModalOptions = (props) => {
  const [modalState, setModalState] = useState(true);
  const handleModalState = () => {
    if (modalState === true) {
      setModalState(false);
      props.load(false);
    } else {
      setModalState(true);
    }
  };

  const handleModalAccept = async () => {
    props.load(true);
    setModalState(false);

    try {
      const res = await httpServiceInstanceNode.post(props.url, props.raw);
      console.log(res, 'hey');

      if (props.module !== undefined) {
        await props.module();
      }

      if (props.comment !== undefined && props.comment !== '') {
        await props.comment();
      }

      if (props.isD2L === false || props.isD2L === undefined) {
        window.location.reload();
      } else {
        props.setNewModal(true);
        props.setHeader(res.respuesta);
      }
    } catch (error) {
      // No hacer nada específico aquí.
    } finally {
      props.load(false);
    }
  };

  return (
    <Modal isOpen={modalState} centered>
      <ModalBody>
        {props.withLoader ? (
          <h4 className="text-center mt-3">Cargando...</h4>
        ) : (
          <h4 className="text-center mt-3">{props.header}</h4>
        )}
      </ModalBody>
      {!props.withLoader && (
        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color={props.color1} onClick={handleModalState}>
            {props.text1}
          </Button>
          <Button
            color={props.color2}
            onClick={props.onAccept ? props.onAccept : handleModalAccept}
          >
            {props.text2}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};
ModalOptions.propTypes = {
  header: PropTypes.any,
  load: PropTypes.any,
  url: PropTypes.any,
  raw: PropTypes.any,
  text1: PropTypes.any,
  color1: PropTypes.any,
  text2: PropTypes.any,
  color2: PropTypes.any,
  module: PropTypes.any,
  comment: PropTypes.any,
  isD2L: PropTypes.any,
  setNewModal: PropTypes.any,
  setHeader: PropTypes.any,
  onAccept: PropTypes.any,
  withLoader: PropTypes.bool,
};
export default ModalOptions;
