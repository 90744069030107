import HttpService from './httpService';

class RecibosApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async buscarListadoRecibos(params) {
    return this.get('buscarListadoRecibos', {
      params,
      responseType: params.excel ? 'blob' : 'json',
    });
  }
}

const recibosApiService = new RecibosApiService(process.env.REACT_APP_NODE_API);
export default recibosApiService;
