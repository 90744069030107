import HttpService from './httpService';

class D2lApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async obtenerAlumnoD2L({ idD2L }) {
    return this.get('obtenerAlumnoD2L', { params: { idD2L } });
  }

  async importarNotasD2L(data, alumnosIds) {
    return this.post('importarNotasD2L', data, alumnosIds);
  }
}

const d2lApiService = new D2lApiService(process.env.REACT_APP_NODE_API);
export default d2lApiService;
