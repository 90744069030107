import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Table,
  Collapse,
  Tooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Col,
  Button,
} from 'reactstrap';
import * as Icon from 'react-feather';
import ModalLoader from '../../layouts/modals/ModalLoader';
import ModalDate from '../../layouts/modals/ModalDate';
import { AuthContext } from '../../context/authContext';
import '../../assets/styles/login/styles.css';
import calculadoraNotasApiService from '../../services/http/calculadoraNotasApiService';
import documentosApiService from '../../services/http/documentosApiService';

const Ciclo = (props) => {
  console.log(props, 'hey');
  const [tooltipBalance, setTooltipBalance] = useState({ tooltipOpen: false });
  const [isLoading, setIsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [isToolTipHoursOpened, setIsToolTipHoursOpened] = useState(false);
  const [isToolTipResultOpened, setIsToolTipResultOpened] = useState(false);
  const [modalDate, setModalDate] = useState(false);
  const [modalDocumentType, setModalDocumentType] = useState('');
  const studentId = JSON.parse(localStorage.getItem('student')).id;
  const empresa = JSON.parse(localStorage.getItem('student')).empresaId;
  const [fecha, setFecha] = useState('');
  const [fechaFinal, setFechaFinal] = useState('');
  const [notaCiclo, setNotaCiclo] = useState(props.ciclo.nota);
  const { userOU } = useContext(AuthContext);

  let tutores = '';
  props.datos.tutores.map((email) => {
    if (tutores === '') {
      tutores += `${email.emailTutor}`;
    } else {
      tutores += ` y ${email.emailTutor}`;
    }
    return null;
  });
  const toggle = (e) => {
    if (
      e.target.id !== '1' &&
      e.target.id !== '2' &&
      e.target.id !== '3' &&
      e.target.id !== 'SENDBOLNOT' &&
      e.target.id !== 'CERCOM' &&
      e.target.id !== 'BOLNOT'
    ) {
      setCollapse(!collapse);
    }
  };

  // const year = `${props.ciclo.anioAcademico.substring(0, 2)} /${props.ciclo.anioAcademico.substring(2, 4)}`
  const toggleTooltipBalance = () => {
    setTooltipBalance((prev) => ({
      tooltipOpen: !prev.tooltipOpen,
    }));
  };

  const download = async () => {
    setModalDate(false);
    setIsLoading(true);

    let idiomaId = '1';

    if (props.datos.personales.idioma === 'Catalán') {
      idiomaId = '2';
    }

    try {
      let blobJustificante = null;
      if (modalDocumentType === 'JUSTIFICANTE') {
        blobJustificante = await documentosApiService.generarJustificante({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
          nombre: props.datos.personales.nombre,
          apellidos: props.datos.personales.apellidos,
          pais: props.datos.personales.paisNacimiento,
          dni: props.datos.personales.documento,
          fecha: props.datos.personales.fechaNacimiento,
          grupo: props.matriculas[props.matriculas.length - 1].fecha,
          ciclo: props.ciclo.nombre,
          fechaj: fecha,
          fechaF: fechaFinal,
        });
      } else if (modalDocumentType === 'CERTIFICADO_HORAS_PRESENCIALES') {
        blobJustificante = await documentosApiService.generarCertificadoHorasPresenciales({
          headquarter: empresa,
          id: studentId,
          idiomaId,
          nombre: props.datos.personales.nombre,
          apellidos: props.datos.personales.apellidos,
          dni: props.datos.personales.documento,
          grupo: props.matriculas[props.matriculas.length - 1].fecha,
          fechaj: fecha,
          fechaF: fechaFinal,
        });
      } else {
        throw new Error('No match document type');
      }

      const blobFinal = blobJustificante.slice(0, blobJustificante.size, 'application/pdf');
      const url = URL.createObjectURL(blobFinal);
      window.open(url, '_blank');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setModalDocumentType('');
    }
  };

  const downloadFile = async (e) => {
    if (e.stopPropagation) e.stopPropagation();

    if (e.target.id === 'CERTIFICADO_HORAS_PRESENCIALES' || e.target.id === 'JUSTIFICANTE') {
      setModalDocumentType(e.target.id);
      setModalDate(true);
      return;
    }

    if (e.target.id === 'CERCOM') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobCercom = await documentosApiService.generarCercom({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
          nombre: props.ciclo.nombre,
          nalumno: props.datos.personales.nombre,
          napellido: props.datos.personales.apellidos,
          dni: props.datos.personales.documento,
          numMatricula: props.matriculas[0].id,
        });

        const blobFinal = blobCercom.slice(0, blobCercom.size, 'application/pdf');
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'BOLNOT') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobBolnot = await documentosApiService.generarBolnot({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
        });

        const blobFinal = blobBolnot.slice(0, blobBolnot.size, 'application/pdf');
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'FoodCOM') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobCertificadoAlimentos = await documentosApiService.generarCertificadoAlimentos({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
          nombre: props.datos.personales.nombre,
          apellidos: props.datos.personales.apellidos,
          pais: props.datos.personales.paisNacimiento,
          dni: props.datos.personales.documento,
          fecha: props.datos.personales.fechaNacimiento,
          grupo: props.matriculas[props.matriculas.length - 1].fecha,
        });

        const blobFinal = blobCertificadoAlimentos.slice(
          0,
          blobCertificadoAlimentos.size,
          'application/pdf',
        );
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'CerMat') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobCertificadoMatricula = await documentosApiService.generarCertificadoMatricula({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
          nombre: props.datos.personales.nombre,
          apellidos: props.datos.personales.apellidos,
          pais: props.datos.personales.paisNacimiento,
          dni: props.datos.personales.documento,
          fecha: props.datos.personales.fechaNacimiento,
          grupo: props.matriculas[props.matriculas.length - 1].fecha,
          ciclo: props.ciclo.nombre,
          fechaj: fecha,
        });

        const blobFinal = blobCertificadoMatricula.slice(
          0,
          blobCertificadoMatricula.size,
          'application/pdf',
        );
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'uni') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobCertificadoUni = await documentosApiService.generarCertificadoUni({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
          nombre: props.datos.personales.nombre,
          apellidos: props.datos.personales.apellidos,
          pais: props.datos.personales.paisNacimiento,
          dni: props.datos.personales.documento,
          fecha: props.datos.personales.fechaNacimiento,
          grupo: props.matriculas[props.matriculas.length - 1].fecha,
          ciclo: props.ciclo.nombre,
          fechaj: fecha,
          n: props.ciclo.nombre,
        });

        const blobFinal = blobCertificadoUni.slice(0, blobCertificadoUni.size, 'application/pdf');
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else if (e.target.id === 'PENDIENTE') {
      setIsLoading(true);
      let idiomaId = '1';
      if (props.datos.escolares.idioma === 'Catalán') {
        idiomaId = '2';
      }

      try {
        const blobPendienteJunta = await documentosApiService.generarPendienteJunta({
          headquarter: empresa,
          id: studentId,
          cursoId: props.ciclo.codigo,
          idiomaId,
          nombre: props.datos.personales.nombre,
          apellidos: props.datos.personales.apellidos,
          pais: props.datos.personales.paisNacimiento,
          dni: props.datos.personales.documento,
          fecha: props.datos.personales.fechaNacimiento,
          grupo: props.matriculas[props.matriculas.length - 1].fecha,
          ciclo: props.ciclo.nombre,
          fechaj: fecha,
        });

        const blobFinal = blobPendienteJunta.slice(0, blobPendienteJunta.size, 'application/pdf');
        const url = URL.createObjectURL(blobFinal);
        window.open(url, '_blank');
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const toggleHours = () => {
    setIsToolTipHoursOpened(!isToolTipHoursOpened);
  };
  const toggleResult = () => {
    setIsToolTipResultOpened(!isToolTipResultOpened);
  };

  const handleCalculateNote = async () => {
    try {
      const data = await calculadoraNotasApiService.calcularNotas({
        id: studentId,
        idplan: props.ciclo.codigo,
        idplan2: props.ciclo.codigo2,
        centro: empresa,
      });
      setNotaCiclo(data.notaCiclo);
    } catch (error) {
      setNotaCiclo(props.ciclo.nota);
    }
  };

  return (
    <>
      {modalDate ? (
        <ModalDate
          state={modalDate}
          dateFunction={download}
          setState={setModalDate}
          setFecha={setFecha}
          setFechaFinal={setFechaFinal}
          fecha={fecha}
          fechaFinal={fechaFinal}
        />
      ) : (
        ''
      )}
      {isLoading ? <ModalLoader header="Generando documento" /> : ''}
      <div className="mb-3">
        <div
          className="border-bottom rounded-top mb-0 py-2"
          style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}
          onClick={(e) => toggle(e)}
        >
          <Container>
            <Row style={{ alignItems: 'center' }}>
              <Col xs="1">
                <Icon.Briefcase size={20} className="align-text-top" />
              </Col>
              <Col xs="6">
                <h4 style={{ margin: 0 }}>{props.matriculas[0].nombre}</h4>
              </Col>
              <Col xs="1">
                <span id="toolTipHours">
                  <Tooltip
                    placement="top"
                    isOpen={isToolTipHoursOpened}
                    target="toolTipHours"
                    toggle={toggleHours}
                  >
                    Horas del ciclo
                  </Tooltip>
                  <Icon.Clock size={20} className="align-text-top" /> <b>{props.ciclo.horas}</b>
                </span>
              </Col>
              <Col xs="1">
                <span id="toolTipResult">
                  {notaCiclo !== '' ? (
                    <>
                      <Tooltip
                        placement="top"
                        isOpen={isToolTipResultOpened}
                        target="toolTipResult"
                        toggle={toggleResult}
                      >
                        Nota final
                      </Tooltip>
                      <Icon.Award size={20} className="align-text-top" /> <b>{notaCiclo}</b>
                    </>
                  ) : (
                    ''
                  )}
                </span>
              </Col>
              <Col xs="2" style={{ display: 'flex', justifyContent: 'center' }}>
                {userOU.centro === 'SUPERADMIN' ||
                userOU.centro === 'CNTEC Girona' ||
                userOU.centro === 'CEDESCA' ||
                userOU.centro === 'CNTEC Sevilla' ||
                userOU.centro === 'SECRETARÍA' ? (
                  <Button color="primary" onClick={handleCalculateNote}>
                    Calcular nota
                  </Button>
                ) : (
                  ''
                )}
              </Col>
              <Col xs="1" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {props.documento.length > 0
                  ? !props.recibosPendientes && (
                      <>
                        <UncontrolledDropdown group className="col-1 w-auto" id={3} direction="up">
                          <DropdownToggle
                            className="bg-transparent border-0 text-dark mr-2"
                            id={1}
                            onClick={(e) => e.stopPropagation && e.stopPropagation()}
                          >
                            <Icon.Download
                              size={20}
                              className="align-text-top"
                              id="Descarga"
                              color={props.balance < 0 ? 'red' : 'black'}
                            />
                          </DropdownToggle>
                          {props.balance >= 0 ? (
                            <DropdownMenu>
                              {props.documento.length > 0
                                ? props.documento.map((doc) => (
                                    <DropdownItem
                                      id={doc.codigo}
                                      onClick={downloadFile}
                                      key={doc.codigo}
                                    >
                                      {doc.nombre}
                                    </DropdownItem>
                                  ))
                                : ''}
                            </DropdownMenu>
                          ) : (
                            <Tooltip
                              placement="top"
                              isOpen={tooltipBalance.tooltipOpen}
                              target="Descarga"
                              toggle={toggleTooltipBalance}
                            >
                              Documentos no disponibles: Balance negativo {props.balance} €
                            </Tooltip>
                          )}
                        </UncontrolledDropdown>
                      </>
                    )
                  : ''}
              </Col>
            </Row>
          </Container>
        </div>
        <Collapse isOpen={collapse} className="border px-2 py-2">
          <Table responsive borderless>
            <thead>
              <tr>
                <th className="text-center">Nombre</th>
                <th className="text-center">Matriculado</th>
                <th className="text-center">Horas</th>
                <th className="text-center">Primera convocatoria</th>
                <th className="text-center">Segunda convocatoria</th>
                <th className="text-center">Tercera convocatoria</th>
                <th className="text-center">Cuarta convocatoria</th>
                <th className="text-center">Gracia</th>
                <th className="text-center">Nota</th>
              </tr>
            </thead>
            <tbody>
              {props.ciclo.modulos.map((modulo, index) => (
                <React.Fragment key={`${modulo.codigo}-${index}`}>
                  <tr
                    style={{ backgroundColor: '#eef5f9' }}
                    className="border-bottom"
                    key={`${Math.floor(Math.random() * 100)}`}
                  >
                    <td>
                      <span style={{ fontWeight: 'bold' }}>{modulo.nombre}</span>
                    </td>
                    <td className="text-center">
                      {modulo.completado === false ? '-' : <Icon.Check size={20} />}
                    </td>
                    <td className="text-center">{modulo.horas}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">{modulo.nota}</td>
                  </tr>
                  {modulo.actividades.map((actividad) => (
                    <tr key={actividad.codigo}>
                      <td style={{ paddingLeft: '20px' }}>{actividad.nombre}</td>
                      <td className="text-center">
                        {actividad.matriculada ? <Icon.Check size={20} /> : ''}
                      </td>
                      <td className="text-center">{actividad.horas}</td>
                      <td className="text-center">{actividad.notaConvocatoria1}</td>
                      <td className="text-center">{actividad.notaConvocatoria2}</td>
                      <td className="text-center">{actividad.notaConvocatoria3}</td>
                      <td className="text-center">{actividad.notaConvocatoria4}</td>
                      <td className="text-center">{actividad.notaConvocatoriaGracia}</td>
                      <td className="text-center">{actividad.nota}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapse>
      </div>
    </>
  );
};
Ciclo.propTypes = {
  recibosPendientes: PropTypes.any,
  ciclo: PropTypes.any,
  datos: PropTypes.any,
  matriculas: PropTypes.any,
  balance: PropTypes.any,
  documento: PropTypes.any,
};
export default Ciclo;
