import HttpService from './httpService';

class OtrosApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async consultaEmpresas({ headquarter }) {
    return this.get('consultaEmpresas', { params: { headquarter } });
  }

  async mostrarLogs() {
    return this.get('mostrarLogs');
  }

  async getOrgUnit() {
    return this.get('getOrgUnit');
  }

  async getPeticionesPendientes(params) {
    return this.get('getPeticionesPendientes', { params });
  }

  async cargarOpcionesSII() {
    return this.get('cargarOpcionesSII');
  }

  async buscarTutores({ centre, curso, grau, modalitat }) {
    return this.get('buscarTutores', {
      params: { centre, curso, grau, modalitat },
    });
  }

  async createDriveFolder({ db, nombre, apellidos, dni, idCentro }) {
    return this.post('createDriveFolder', { db, nombre, apellidos, dni, idCentro });
  }
}

const otrosApiService = new OtrosApiService(process.env.REACT_APP_NODE_API);
export default otrosApiService;
