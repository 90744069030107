import { Col } from 'reactstrap';
import ComponentCard from '../../layouts/containers/ComponentCard';
import FormularioRecibos from '../../components/recibos/FormularioRecibos';

const ListadoRecibos = () => {
  return (
    <>
      <Col md="12">
        <ComponentCard title="Listado de recibos" hasButton>
          <FormularioRecibos />
        </ComponentCard>
      </Col>
    </>
  );
};
export default ListadoRecibos;
