import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../context/authContext';
import ModalOptions from '../../../layouts/modals/ModalOptions';
import matriculacionApiService from '../../../services/http/matriculacionApiService';
import ModalClickable from '../../../layouts/modals/ModalClickable';

const Acciones = ({
  isNoPresencial,
  curso,
  comentarios,
  modulos,
  activity,
  hoursCounter,
  precioFinal,
}) => {
  const navigate = useNavigate();
  const enrollmentSession = JSON.parse(localStorage.getItem('enrollment'));
  const [balance, setBalance] = useState(-1);
  const { userOU } = useContext(AuthContext);
  const [dataApi, setDataApi] = useState([]);
  const [tooltipBalance, setTooltipBalance] = useState({ tooltipOpen: false });
  const [isOpen, setIsOpen] = useState(false);
  const [isToolTipHoursOpened, setIsToolTipHoursOpened] = useState(false);
  const [url, setUrl] = useState('');
  const [raw, setRaw] = useState({});
  const [errorEstados, setErrorEstados] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataBalance = await matriculacionApiService.consultarBalance({
          headquarter: enrollmentSession.headquarter,
          id: enrollmentSession.id,
        });
        setBalance(parseFloat(dataBalance.Balance));
      } catch (error) {
        setBalance(-1);
      }

      try {
        const dataMatricula = await matriculacionApiService.detalleMatricula({
          headquarter: enrollmentSession.headquarter,
          id: enrollmentSession.id,
        });
        setDataApi(dataMatricula);
      } catch (error) {
        setDataApi([]);
      }
    };

    fetchData();
  }, []);

  const updateComment = async () => {
    try {
      await matriculacionApiService.agregarComentario({
        headquarter: enrollmentSession.headquarter,
        numMatricula: enrollmentSession.id,
        comment: comentarios,
      });

      window.location.reload();
    } catch (error) {
      // No hacer nada específico aquí.
    }
  };

  const updateModules = async () => {
    try {
      await matriculacionApiService.actualizarModulos({
        headquarter: enrollmentSession.headquarter,
        numMatricula: enrollmentSession.id,
        modulos,
        activity,
      });
    } catch (error) {
      // No hacer nada específico aquí.
      console.error(error);
    }
  };

  const handleOnClick = () => {
    navigate(-1);
  };

  // Función para cambiar el estado (Sede - Secretaria)
  const handleState = (state) => {
    setIsOpen(true);
    const rawInfo = {
      state,
      headquarter: enrollmentSession.headquarter,
      numMatricula: enrollmentSession.id,
      nombre: dataApi[0].sNombre,
      studentEmail: enrollmentSession.email,
      idDelegacion: enrollmentSession.headquarter === 'CEDESCA' ? userOU.idCedesca : userOU.idCntec,
      comentarios,
      curso,
    };
    setRaw(rawInfo);
    setUrl(`${process.env.REACT_APP_NODE_API}cambiarEstado`);
  };

  const handleSede = () => {
    handleState('6');
  };
  const handleSecretaria = () => {
    handleState('5');
  };

  const toggleTooltipBalance = () => {
    setTooltipBalance((prev) => ({
      tooltipOpen: !prev.tooltipOpen,
    }));
  };
  const toggleHours = () => {
    setIsToolTipHoursOpened(!isToolTipHoursOpened);
  };

  // Función para confirmar la petición
  const handleConfirm = () => {
    const actividadesValidas = [];

    // Usamos Object.entries para obtener las claves y los valores de 'activity'
    Object.entries(activity).forEach(([key, actividades]) => {
      console.log(key);
      // Recorremos cada actividad dentro del módulo de 'activity'
      actividades.forEach((act) => {
        // Creamos la cadena que representa la combinación de modulo y descripcion
        const descripcionCompleta = `${act.modulo} ${act.descripcion}`;

        // Verificamos si la descripcion completa está en los modulos y si idEstado es -1
        if (modulos.includes(descripcionCompleta) && act.idEstado === -1) {
          actividadesValidas.push(act);
        }
      });
    });
    setErrorEstados(actividadesValidas);
    // Si no se encontraron actividades válidas, actualizamos los módulos
    if (actividadesValidas.length === 0) {
      setIsOpen(true);
      const rawInfo = {
        headquarter: enrollmentSession.headquarter,
        numMatricula: enrollmentSession.id,
        nombre: dataApi[0].sNombre,
        emailAlumno: enrollmentSession.email,
        idDelegacion:
          enrollmentSession.headquarter === 'CEDESCA' ? userOU.idCedesca : userOU.idCntec,
        comentarios,
        curso,
        precioTotal: precioFinal,
      };
      setRaw(rawInfo);
      setUrl(`${process.env.REACT_APP_NODE_API}confirmarPeticion`);
    }
  };

  return (
    <>
      {errorEstados.length > 0 && (
        <ModalClickable
          header={`Faltan estados por definir en las actividades: ${errorEstados
            .map((e) => {
              return e.modulo + e.descripcion;
            })
            .join(', ')}`}
        />
      )}

      {isOpen && (
        <ModalOptions
          header="¿Desea realizar estos cambios?"
          url={url}
          raw={raw}
          load={setIsOpen}
          text1="Cancelar"
          color1="danger"
          text2="Aceptar"
          color2="primary"
          comment={comentarios.length > 0 ? updateComment : ''}
          module={updateModules}
        />
      )}
      {dataApi.length > 0 && dataApi[0].estado === 'Activa' && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="float-end me-1" onClick={handleOnClick}>
            Atrás
          </Button>
          {userOU.centro === 'SUPERADMIN' ||
          userOU.centro === 'CNTEC Girona' ||
          userOU.centro === 'CEDESCA' ||
          userOU.centro === 'CNTEC Sevilla' ||
          userOU.centro === 'SECRETARÍA' ? (
            <Button
              className="float-end"
              color="success"
              disabled={(isNoPresencial && hoursCounter > 2000) || comentarios.length === 0}
              onClick={handleConfirm}
            >
              Guardar cambios
            </Button>
          ) : (
            <Button
              className="float-end"
              color="success"
              onClick={updateComment}
              disabled={comentarios.length === 0}
            >
              Enviar comentario
            </Button>
          )}
        </div>
      )}
      {dataApi.length > 0 && dataApi[0].estado === 'Revisar delegación' && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="float-end me-1" onClick={handleOnClick}>
            Atrás
          </Button>

          {userOU.centro === 'SUPERADMIN' ||
          userOU.centro === 'CNTEC Girona' ||
          userOU.centro === 'CEDESCA' ||
          userOU.centro === 'CNTEC Sevilla' ? (
            <Button
              className="float-end"
              color="success"
              onClick={updateComment}
              disabled={comentarios.length === 0}
            >
              Enviar comentario
            </Button>
          ) : (
            <Button
              className="float-end"
              color="success"
              onClick={handleSecretaria}
              disabled={(isNoPresencial && hoursCounter > 2000) || comentarios.length === 0}
            >
              Confirmar revisión
            </Button>
          )}
        </div>
      )}
      {dataApi.length > 0 && dataApi[0].estado === 'Revisar secretaria' && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="float-end me-1" onClick={handleOnClick}>
            Atrás
          </Button>
          {userOU.centro === 'SUPERADMIN' ||
          userOU.centro === 'CNTEC Girona' ||
          userOU.centro === 'CEDESCA' ||
          userOU.centro === 'CNTEC Sevilla' ||
          userOU.centro === 'SECRETARÍA' ? (
            <>
              <span id="toolTipHours">
                {comentarios.length === 0 ? (
                  <Tooltip
                    placement="top"
                    isOpen={isToolTipHoursOpened}
                    target="toolTipHours"
                    toggle={toggleHours}
                  >
                    Realice cambios o escriba un comentario antes de rechazar.
                  </Tooltip>
                ) : (
                  ''
                )}

                <Button
                  className="float-end"
                  color="warning"
                  onClick={handleSede}
                  disabled={comentarios.length === 0}
                >
                  Rechazar solicitud
                </Button>
              </span>

              <div id="Test">
                <Button
                  className="float-end"
                  color="success"
                  onClick={handleConfirm}
                  disabled={balance < 0 || (isNoPresencial && hoursCounter > 2000)}
                >
                  Confirmar solicitud
                </Button>
              </div>
              {balance < 0 ? (
                <Tooltip
                  placement="top"
                  isOpen={tooltipBalance.tooltipOpen}
                  target="Test"
                  toggle={toggleTooltipBalance}
                >
                  No es posible confirmar la solicitud porque el alumno tiene un balance negativo de{' '}
                  {balance} €
                </Tooltip>
              ) : (
                ''
              )}
            </>
          ) : (
            <Button
              className="float-end"
              color="success"
              onClick={updateComment}
              disabled={comentarios.length === 0}
            >
              Enviar comentario
            </Button>
          )}
        </div>
      )}
      {dataApi.length > 0 && dataApi[0].estado === 'Baja' && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="float-end me-1" onClick={handleOnClick}>
            Atrás
          </Button>

          <Button
            className="float-end"
            color="success"
            onClick={updateComment}
            disabled={comentarios.length === 0}
          >
            Enviar comentario
          </Button>
        </div>
      )}
      {dataApi.length > 0 && dataApi[0].estado === 'Pendiente baja' && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="float-end me-1" onClick={handleOnClick}>
            Atrás
          </Button>

          <Button
            className="float-end"
            color="success"
            onClick={updateComment}
            disabled={comentarios.length === 0}
          >
            Enviar comentario
          </Button>
        </div>
      )}
    </>
  );
};
Acciones.propTypes = {
  isNoPresencial: PropTypes.any,
  curso: PropTypes.any,
  comentarios: PropTypes.any,
  modulos: PropTypes.any,
  activity: PropTypes.any,
  hoursCounter: PropTypes.any,
  precioFinal: PropTypes.any,
};
export default Acciones;
