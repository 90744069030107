import { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import Datetime from 'react-datetime'; // Importar react-datetime
import moment from 'moment'; // Importar moment
import Loader from '../../layouts/loader/Loader';
import funcionalidadesApiService from '../../services/http/funcionalidadesApiService';
import ModalClickable from '../../layouts/modals/ModalClickable';

import 'react-datetime/css/react-datetime.css'; // Importar los estilos de react-datetime

const PanelDeControl = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datos = await funcionalidadesApiService.cargarFuncionalidades({
          headquarter: 'CEDESCA',
        });
        setIsLoading(false);
        setData(datos);
      } catch (error) {
        setIsLoading(false);
        setData([]);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const saveData = async () => {
    try {
      // Formatear las fechas antes de enviarlas al backend
      const formattedData = data.map((item) => ({
        ...item,
        fechaInicio: item.fechaInicio ? moment(item.fechaInicio).format('YYYY-MM-DD') : null,
        fechaFin: item.fechaFin ? moment(item.fechaFin).format('YYYY-MM-DD') : null,
      }));

      // Enviar los datos formateados al backend
      await funcionalidadesApiService.guardarFuncionalidades({
        data: formattedData,
      });
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleToggle = (index) => {
    const updatedData = [...data];
    updatedData[index].estado = updatedData[index].estado === 1 ? 0 : 1;
    setData(updatedData);
  };

  const handleFechaChange = (index, field, date) => {
    const updatedData = [...data];
    updatedData[index][field] = date;

    // Si la fecha de inicio es posterior o igual a fechaFin, actualizamos fechaFin
    if (
      field === 'fechaInicio' &&
      updatedData[index].fechaFin &&
      date >= updatedData[index].fechaFin
    ) {
      updatedData[index].fechaFin = new Date(date.getTime() + 24 * 60 * 60 * 1000); // Por ejemplo, añadir un día
    }
    setData(updatedData);
  };

  return (
    <div>
      {isSaved && <ModalClickable header="Cambios guardados" />}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h4>Funcionalidades</h4>
          {data.map((item, index) => (
            <FormGroup switch key={item.id}>
              <Label check>{item.nombre}</Label>
              <Input
                type="switch"
                checked={item.estado === 1}
                onChange={() => handleToggle(index)}
              />

              {/* Texto encima de los calendarios */}
              {item.fechaInicio && item.fechaFin && (
                <div>
                  <Label>Elegir la fecha de inicio y fin de matriculación</Label>
                  <div className="row mt-3">
                    <div className="col-6">
                      <Label>Fecha de inicio:</Label>
                      <Datetime
                        value={new Date(item.fechaInicio)}
                        onChange={(date) => handleFechaChange(index, 'fechaInicio', date)}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false} // Solo mostrar la fecha
                        closeOnSelect
                        className="form-control"
                      />
                    </div>
                    <div className="col-6">
                      <Label>Fecha de fin:</Label>
                      <Datetime
                        value={new Date(item.fechaFin)}
                        onChange={(date) => handleFechaChange(index, 'fechaFin', date)}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        minDate={new Date(item.fechaInicio)} // fechaFin debe ser posterior a fechaInicio
                        closeOnSelect
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
            </FormGroup>
          ))}

          <div className="d-flex justify-content-end mt-3">
            <Button onClick={saveData}>Guardar cambios</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PanelDeControl;
